/* RESPONSIVE VIDEO HOME */
@media only screen and (max-width: 400px) {
  #video {
    width: 100%;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 450px) {
  #video {
    width: 100%;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 769px) {
  #video {
    width: 100%;
    margin-top: 0px;
  }
}

@media screen and (min-width: 1024px) {
  .adatta {
    font-size: 70px;
  }
}

.profile-img {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.about-text {
  font-family: "Montserrat", sans-serif;
  color: white;
  line-height: 1.6;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .profile-img {
    width: 100px;
    height: 100px;
  }
}

/* Riduzione del margin-top per tablet (768px) */

@media only screen and (max-width: 668px) {
}

@media only screen and (max-width: 769px) {
}

@media only screen and (max-width: 1026px) {
}

@media only screen and (max-width: 1444px) {
}

/* Personalizzare il contorno di focus */
.btn:focus {
  box-shadow: 0 0 0 2px rgba(158, 96, 107, 0.5);
}
/* spazio sopra la scritta about */
.card-title {
  font-size: 18px;
  margin-top: 50px;
}

.about-section p {
  text-align: justify;
}

.about-title {
  font-size: 24px;
  color: #333;
}

.about-section .about-row {
  display: flex;
  align-items: center;
}

.card-text {
  margin-bottom: 20px;
  margin-top: 20px;
}

/* CARD INTERESSI  */
.col-md-2 {
  margin-right: -7px;
}

.card-sm {
  max-width: 150px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  margin-bottom: -10px;
}

.card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Overlay con gradiente */
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card:hover .overlay {
  opacity: 1;
}

.card .card-body {
  text-align: center;
}

.interest-image {
  position: relative;
  overflow: hidden;
}

.interest-img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  transition: transform 0.3s;
}

.hover-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.3s;
}

.interest-image:hover .hover-text {
  opacity: 1;
}

.interest-image:hover .interest-img {
  transform: scale(1.1);
}

.interest-image {
  position: relative;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* su di noi */
.about-title {
  font-size: 2.5rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

.about-subtitle {
  font-size: 1.75rem;
  margin-bottom: 1rem;
}

.about-text {
  font-size: 1rem;
  line-height: 1.5;
}

.decorative-divider {
  width: 100%;
  text-align: center;
  margin: 20px 0;
  position: relative;
}

.decorative-divider::before {
  content: "• • •";
  font-size: 24px;
  color: black; /* Colore dei punti */
  padding: 0 15px;
  position: relative;
  z-index: 1;
  background: white;
  padding: 0 10px;
}

.decorative-divider::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: black;
  z-index: 0;
}

/* gallery */
.photo-gallery .gallery-item {
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  height: 250px;
  border-radius: 5px;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.photo-gallery .gallery-item.is-visible {
  opacity: 1;
  transform: translateY(0);
}

.photo-gallery .gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* sezione ig */
.instagram-gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.instagram-image {
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease;
}

.instagram-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.instagram-image:hover {
  transform: scale(1.05);
}

.gallery-item {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.gallery-item img {
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

.gallery-overlay button {
  background-color: #c471ed;
  border: none;
  color: #fff;
  padding: 10px 20px;
  font-family: "Poppins, sans-serif";
  font-size: 14px;
  cursor: pointer;
  border-radius: 20px;
}

.about-text a:hover .underline {
  transform: scaleX(1);
}

/* Sezione Instagram */
.instagram-section {
  padding: 1px 0;
  background-color: #eac2fc;
  text-align: center;
  position: relative;
}

.instagram-section .content {
  position: relative;
  z-index: 2;
  background-color: #eac2fc;
  padding: 20px;
  border-radius: 15px;
}

.instagram-section .content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.instagram-section .content p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #6c757d;
}

.instagram-section .instagram-link {
  display: inline-block;
  padding: 12px 25px;
  font-size: 1rem;
  background-color: #c471ed;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  border: 2px solid transparent;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.instagram-section .instagram-link:hover {
  background-color: transparent;
  color: #c471ed;
  border-color: #c471ed;
}

.instagram-section .image-container {
  max-width: 70%;
  height: auto;
  text-align: right;
}

@media (min-width: 768px) {
  .instagram-section .content {
    text-align: left;
    margin-right: auto;
  }
  .instagram-section .image-container {
    max-width: 40%;
  }
}

@media (max-width: 767px) {
  .instagram-section .content h2 {
    font-size: 2rem;
  }
  .instagram-section .content p {
    font-size: 1rem;
  }
  .instagram-section .instagram-link {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
  .instagram-section .image-container {
    margin-top: 20px;
    max-width: 100%;
    text-align: center;
  }
}

/* servizi */
.services-section {
  background-color: #f8f9fa;
  padding-top: 50px;
  padding-bottom: 50px;
}

.service-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}
