.footer {
  background-color: #282d31;
  color: #fff;
  text-align: center;
  padding: 20px;
  position: relative;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.footer-content p {
  margin: 0 0 10px 0;
  font-size: 14px;
}

.social-icons {
  display: flex;

  margin-bottom: 10px;
}

.social-icons a {
  margin: 0 5px;
  display: inline-block;
}

.footer-icon {
  width: 35px;
  height: 35px;
}

.footer-image {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.footer-image img {
  max-width: 100%;
  height: auto;
}

.scroll-to-top {
  position: absolute;
  right: 30px;
  bottom: 20px;
  background-color: #c471ed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-to-top svg {
  fill: #fff;
}
