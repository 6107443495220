.carousel-container {
  margin-top: 2em;
  position: relative;
  width: 100%;
  overflow: visible;
  font-family: "Montserrat", sans-serif;
}

.carousel-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  position: relative;
  z-index: 1;
}

.carousel-slide:hover {
  z-index: 2;
}

.carousel-slide img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.carousel-slide:hover img,
.carousel-slide:hover .image-caption {
  transform: scale(1.3);
}

.image-caption {
  position: absolute;
  bottom: 10px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 10;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.carousel-slide:hover .image-caption {
  opacity: 1;
}

/* Stile per i controlli del carosello */
.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
  color: white;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.slick-prev {
  left: 25px;
}

.slick-next {
  right: 25px;
}

.slick-prev:before {
  content: "‹";
}

.slick-next:before {
  content: "›";
}

.slick-prev:hover,
.slick-next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.additional-content {
  background-color: #8b5baf;
  color: white;
  padding: 20px;
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  text-align: center;
}

.additional-content h3 {
  margin-top: 10px;
  color: #281e55;
  text-align: center;
  font-weight: bolder;
}

.additional-content p {
  font-size: 16px;
  line-height: 1.5;
}

.title h2 {
  font-family: "Poppins", sans-serif;
  color: black;
  background-color: #eacdf1;
  padding: 0.2rem 0.5rem;
  font-size: 25px;
  display: inline-block;
  margin: 49px auto 0;
}

.title {
  text-align: center;
}

.title p {
  font-family: "Poppins", sans-serif;
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 20px;
}
