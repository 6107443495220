body {
  font-family: "Montserrat, sans-serif";
  color: black;
  margin: 0;
  padding: 0;
}

.contact-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hero-section {
  background-size: cover;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  padding: 20px;
}

.hero-section h1 {
  font-size: 3em;
  margin: 0;
}

.hero-section p {
  margin: 0;
  font-size: 1.5em;
}

.scroll-down {
  color: white;
  padding: 10px 20px;
  border: 2px solid white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.scroll-down:hover {
  background-color: #b3870b;
  transform: translateY(-5px);
}

.intro {
  background-color: white;
  padding: 40px;
  text-align: center;
}

.card {
  background-color: white;
  padding: 20px;
  margin: 0 auto;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.card h2 {
  font-size: 2em;
  margin-bottom: 10px;
}

.what-i-do {
  padding: 40px;
  text-align: center;
}

.what-i-do h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.grid-item {
  background-color: white;
  padding: 20px;
  width: 200px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}

.grid-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-5px);
}

.grid-item .icon {
  font-size: 2em;
  margin-bottom: 10px;
}

.grid-item h3 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.contact-form {
  background-color: #fafafa;
  padding: 40px;
  text-align: center;
}

.contact-form h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.contact-form form {
  max-width: 600px;
  margin: 0 auto;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.contact-form label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.contact-form button {
  padding: 10px 20px;
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1em;
}

.contact-form button:hover {
  background-color: white;
}

.hero-section h1,
.hero-section p,
.hero-section .scroll-down {
  color: white;
}

/* Media Queries */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2em;
  }

  .hero-section p {
    font-size: 1.2em;
  }

  .scroll-down {
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 1.5em;
  }

  .hero-section p {
    font-size: 1em;
  }

  .scroll-down {
    padding: 6px 12px;
  }
}

/* form */
.contact-container {
  margin-top: 100px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  flex-direction: row;
}

.image-section {
  flex: 1;
  background-size: cover;
  background-position: center;
  min-height: 900px;
}

.form-section {
  flex: 1;
  padding: 40px;
}

/* Media query per schermi piccoli */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    margin-top: 80px;
  }

  .image-section {
    min-height: 400px;
  }

  .form-section {
    padding: 20px;
  }

  .button-container button {
    width: 100%;
  }
}

/* Media query per schermi molto piccoli (come i telefoni) */
@media (max-width: 480px) {
  .contact-container {
    margin-top: -194px;
  }

  .form-section {
    padding: 15px;
  }

  .image-section {
    min-height: 300px;
  }
}
