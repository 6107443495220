/* Container principale */
.gestione-social-container {
  font-family: "Poppins", sans-serif;
  color: #333;
  line-height: 1.6;
}

/* Sezione Hero */
.hero-section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}

.hero-content {
  position: relative;
  z-index: 1;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}

.hero-content h1 {
  font-size: 48px;
  font-weight: 700;
  background: linear-gradient(45deg, #c471ed, #eac2fc);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 18px;
  color: #f0f0f0;
  margin-bottom: 30px;
}

/* Pulsante CTA (Call To Action) */
.cta-button {
  background-color: #c471ed;
  color: #fff;
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.cta-button:hover {
  background-color: #8b5baf;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
  text-decoration: none;
}

/* Sezione Servizi */

.services-section {
  padding: 50px 20px;
  background-color: #eac2fc;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  font-size: 32px;
  margin-bottom: 40px;
  color: #c471ed;
}

/* Flexbox layout for service items */
.service-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.service-item ul {
  list-style-type: none;
  list-style-type: disc;
  padding-left: 20px;
  text-align: left;
}

.service-item li {
  margin-bottom: 10px;
}

.service-item h3 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.service-item p {
  font-size: 18px;
  color: #555;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle-button {
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-weight: bold;
  color: #c471ed;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.service-details {
  margin-top: 10px;
  font-size: 16px;
  color: #555;
  padding-left: 20px;
  list-style-type: disc;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 10px 10px 20px 3px rgba(196, 113, 237, 0.5);
}

/* Contact Section */
.contact-section {
  padding: 60px 20px;
  background-color: #8b5baf;
  text-align: center;
  color: #fff;
}

.contact-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700;
  color: #eac2fc;
}

.contact-section .cta-button {
  background-color: #c471ed;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 30px;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}

.contact-section .cta-button:hover {
  background-color: #eac2fc;
  color: #8b5baf;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-3px);
}

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .section-title {
    font-size: 28px;
  }

  .service-item h3 {
    font-size: 20px;
  }

  .service-item p {
    font-size: 14px;
  }

  .contact-section h2 {
    font-size: 24px;
  }

  .contact-section .cta-button {
    font-size: 18px;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .hero-content h1 {
    font-size: 24px;
  }

  .hero-content p {
    font-size: 14px;
  }

  .cta-button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .service-item h3 {
    font-size: 18px;
  }

  .service-item p {
    font-size: 12px;
  }

  .contact-section h2 {
    font-size: 20px;
  }

  .contact-section .cta-button {
    font-size: 16px;
    padding: 10px 16px;
  }
}

.service-item {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  max-width: 800px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 10px 10px 20px rgba(196, 113, 237, 0.5);
}
