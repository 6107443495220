/* SEO Title Section */
.seo-title-section {
  text-align: center;
  margin: 2rem 0;
  font-family: "Poppins", sans-serif;
  color: #333;
}

.seo-title-section h2 {
  font-size: 36px;
  color: #4b3f54;
}

.seo-description {
  font-size: 18px;
  color: #4b3f54;
  padding: 20px;
  box-sizing: border-box;
  line-height: 1.6;
}

/* SEO Services List Section */
.seo-services-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 2rem 0;
  font-family: "Poppins", sans-serif;
}

/* SEO Individual Service Item */
.seo-service-item {
  flex: 1 1 calc(50% - 2rem);
  margin: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

/* SEO Service Image */
.seo-service-image {
  width: 100%;
  height: auto;
  border-bottom: 3px solid #d090d8;
}

/* SEO Service Content */
.seo-service-content {
  padding: 1rem;
  text-align: center;
}

.seo-service-content h3 {
  font-size: 22px;
  font-weight: bold;
  color: #4b3f54;
  margin-bottom: 15px;
}

.seo-service-content p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

/* Contact Me Section */
.seo-contact-section {
  background-color: #f8f9fa;
  padding: 3rem 1rem;
}

.seo-contact-heading {
  font-size: 28px;
  font-weight: bold;
  color: #4b3f54;
}

.seo-contact-description {
  font-size: 18px;
  color: #4b3f54;
}

.seo-contact-button {
  background-color: #d090d8;
  border-color: #d090d8;
  color: #fff;
  font-weight: 600;
}

.seo-contact-button:hover {
  background-color: #a66fb3;
  border-color: #a66fb3;
}

.seo-contact-button-outline {
  color: #4b3f54;
  border-color: #4b3f54;
}

.seo-contact-button-outline:hover {
  background-color: #4b3f54;
  color: #fff;
}

/* LinkedIn Section */
.linkedin-section {
  background-color: #eac2fc;
  padding: 3rem 1rem;
  text-align: center;
  color: #4b3f54;
}

.linkedin-section h2 {
  font-size: 28px;
  font-weight: bold;
  color: #4b3f54;
  margin-bottom: 1rem;
}

.linkedin-section p {
  font-size: 18px;
  color: #4b3f54;
  margin-bottom: 2rem;
}

.linkedin-button {
  background-color: #d090d8;
  border-color: #d090d8;
  color: #fff;
  padding: 10px 30px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.linkedin-button:hover {
  background-color: #b376b6;
}

/* Footer Section */
.footer-section {
  background-color: #333;
  color: #fff;
  padding: 2rem 0;
  text-align: center;
}

.footer-section p {
  margin: 0;
  font-size: 14px;
}

.footer-social-icons {
  margin-top: 1rem;
}

.footer-social-icons a {
  margin: 0 10px;
  color: #fff;
  font-size: 24px;
}

.footer-social-icons a:hover {
  color: #d090d8;
}

.linkedin-button {
  background-color: #d090d8;
  border-color: #d090d8;
  color: #fff;
  padding: 10px 30px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

/* Keep the text color the same after the link has been visited */
.linkedin-button:visited,
.linkedin-button:focus,
.linkedin-button:hover {
  color: #fff;
  background-color: #b376b6;
}

.linkedin-button:hover {
  background-color: #b376b6;
}

/* Hover effect for individual service items */
.seo-service-item:hover {
  transform: translateY(-10px);
  background-color: #eac2fc;
  transition: transform 0.3s ease, background-color 0.3s ease;
}
