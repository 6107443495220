.about-us-container {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-image img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us-text {
  margin-left: 20px;
}

.about-us-text h2 {
  font-family: "Playfair Display", serif;
  font-size: 28px;
  color: #d4a373;
}

.about-us-text p {
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.about-us-text strong {
  font-weight: bold;
}

@media (max-width: 769px) {
  #responsive {
    margin-top: 32px;
  }
}

/* Stile base per il contenuto */
.container,
.about-us-container,
.noi-section,
.extra-section,
.stories-testimonials-section {
  padding: 20px;
  text-align: center;
}

/* Dispositivi mobili piccoli (320px) */
@media only screen and (max-width: 320px) {
  .about-us-container {
    margin-top: 100px;
  }

  .about-us-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 10px;
  }

  .about-us-text h2 {
    font-size: 1.5rem;
  }

  .about-us-text p {
    font-size: 0.9rem;
  }
}

/* Dispositivi mobili medi (375px) */
@media only screen and (max-width: 375px) {
  .about-us-container {
    margin-top: 100px;
  }

  .about-us-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 15px;
  }

  .about-us-text h2 {
    font-size: 1.75rem;
  }

  .about-us-text p {
    font-size: 1rem;
  }
}

/* Dispositivi mobili grandi (425px) */
@media only screen and (max-width: 425px) {
  .about-us-container {
    margin-top: 100px;
  }

  .about-us-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 20px;
  }

  .about-us-text h2 {
    font-size: 1.85rem;
  }

  .about-us-text p {
    font-size: 1.1rem;
  }
}

/* Tablet (768px) */
@media only screen and (max-width: 768px) {
  .about-us-container {
    margin-top: 150px;
  }

  .about-us-image img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 30px;
  }

  .about-us-text h2 {
    font-size: 2rem;
  }

  .about-us-text p {
    font-size: 1.2rem;
  }

  .extra-section-content {
    flex-direction: column;
    align-items: center;
  }

  .extra-section-text,
  .extra-section-image {
    order: unset;
    padding: 10px;
    text-align: center;
  }
}

/* Laptop (1024px) */
@media only screen and (max-width: 1024px) {
  .about-us-container {
    margin-top: 150px;
  }

  .about-us-image img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 40px;
  }

  .about-us-text h2 {
    font-size: 2.25rem;
  }

  .about-us-text p {
    font-size: 1.25rem;
  }
}

/* Laptop grande (1440px) */
@media only screen and (max-width: 1440px) {
  .about-us-container {
    margin-top: 200px;
  }

  .about-us-image img {
    width: 70%;
    height: auto;
    border-radius: 10px;
  }

  .about-us-text {
    padding: 0 50px;
  }

  .about-us-text h2 {
    font-size: 2.5rem;
  }

  .about-us-text p {
    font-size: 1.3rem;
  }
}

/* animazione img */

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(40px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Applica l'animazione più evidente alle immagini */
.extra-section-image img,
.extra-section .extra-section-image img {
  animation: fadeIn 1.5s ease-out;
}

/* Stili per la sezione "Cosa facciamo" */
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-item {
  flex: 1 1 45%;
  max-width: 45%;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .service-item {
    flex: 1 1 90%;
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .service-item {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

/* Classe per rimuovere la sottolineatura */
.no-underline {
  text-decoration: none;
  color: inherit;
}

.no-underline:hover {
  text-decoration: none;
  color: inherit;
}
