/*☑️NAVBAR */

.nav-link span {
  transition: border-bottom 0.3s ease;
}

/* Per collapse */

.custom-toggler {
  border: none;
  background-color: transparent;
  position: relative;
  width: 30px;
  height: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-toggler .bar {
  display: block;
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
}

.custom-toggler .bar-top {
  top: 5px;
}

.custom-toggler .bar-middle {
  top: 11px;
}

.custom-toggler .bar-bottom {
  top: 17px;
}

.custom-toggler.active .bar-top {
  top: 5px; /* Rimane in posizione originale */
}

.custom-toggler.active .bar-middle {
  display: none; /* Nasconde la barra centrale */
}

.custom-toggler.active .bar-bottom {
  top: 17px; /* Rimane in posizione originale */
}

/* Font */
.nav-link {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 4px;
}

/* .custom-navbar {
  z-index: 1050;
  position: relative !important;
  top: auto;
} */

#menu_button {
  padding: 0;
  margin-right: 3%;
}

#menu_items {
  margin-right: 1%;
}
